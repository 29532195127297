<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <div class="">
          <v-card class="elevation-1">
            <div class="tab-panel-">
              <v-tabs v-model="model" color="white" slider-color="primary">
                <v-tab
                  v-for="(tab, i) in tabItems"
                  :key="i"
                  :href="`#tab-${i}`"
                >
                  {{ tab }}
                </v-tab>
              </v-tabs>
            </div>
          </v-card>
        </div>
        <div
          class="dash-container info-tab-section py-10"
          style="width: 100%; margin-top: 0"
        >
            <div v-if="model === 'tab-0'">
              <studentBirthdayList></studentBirthdayList>
            </div>
            <div v-if="model === 'tab-1'">
              <birthdayList filterType='guardian'></birthdayList>
            </div>
            <div v-if="model === 'tab-2'">
              <birthdayList filterType='staff'></birthdayList>
            </div>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import studentBirthdayList from "../StudentBirthdayList/index.vue";
import birthdayList from "./pages/birthDayList.vue"
export default {
  components: {
    studentBirthdayList,birthdayList
  },
  data: () => ({
    model: "",
    tabItems: ["student", "guardian", "staff"],
    
  }),
};
</script>
